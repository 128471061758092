var render = function render(){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" The current selection, spanning the "),_c('span',{staticClass:"ph1 bg-primary-accent white--text font-mono"},[_vm._v(_vm._s(_vm.computed.selectedDecade))]),_vm._v(", "),(
        _vm.state.activeFilters['locations'].length ||
        _vm.state.activeFilters['subjects'].length
      )?_c('span',[_c('br'),_c('span',[_vm._v("filtered for")]),(_vm.state.activeFilters['locations'].length)?_c('span',_vm._l((_vm.state.activeFilters['locations']),function(location){return _c('v-chip',{key:location,staticClass:"ml1 teal white--text font-mono",attrs:{"value":location,"label":""},on:{"click":function($event){return _vm.$emit('toggle-active-filter', {
              type: 'locations',
              value: location,
            })}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-map-marker")]),_c('strong',[_vm._v(_vm._s(location))]),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-close-circle")])],1)}),1):_vm._e(),(_vm.state.activeFilters['subjects'].length)?_c('span',_vm._l((_vm.state.activeFilters['subjects']),function(subject){return _c('v-chip',{key:subject,staticClass:"ml1 teal white--text font-mono",attrs:{"value":subject,"label":""},on:{"click":function($event){return _vm.$emit('toggle-active-filter', {
              type: 'subjects',
              value: subject,
            })}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-tag")]),_c('strong',[_vm._v(_vm._s(subject))]),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-close-circle")])],1)}),1):_vm._e(),_c('span',[_vm._v(",")])]):_vm._e(),_c('br'),_vm._v(" contains "),_c('span',{staticClass:"ph1 white black--text font-mono"},[_vm._v(_vm._s(_vm.computed.activeLength))]),_vm._v(" out of "),_c('span',{staticClass:"ph1 white black--text font-mono"},[_vm._v(_vm._s(_vm.computed.totalLength))]),_vm._v(" videos. ")])
}
var staticRenderFns = []

export { render, staticRenderFns }