var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex expansion-container"},[_c('div',{staticClass:"expansion-left tc bg-black ba b--grey--darken-3"},[_c('video',{ref:"gridVideo",staticClass:"outline-0 bg-black",style:({ 'max-width': _vm.videoMaxWidth + 'px' }),attrs:{"src":_vm.item.videoSrc,"poster":_vm.item.thumbSrc,"controls":"","width":"100%","controlsList":"nodownload nofullscreen noremoteplayback","disablePictureInPicture":""},on:{"playing":_vm.onVideoPlayChange,"pause":_vm.onVideoPlayChange}})]),_c('div',{staticClass:"expansion-right pa3 relative grey darken-3"},[_c('h2',{staticClass:"mt0 f4 mr4"},[_vm._v(" "+_vm._s(_vm.item.title)+" "),_c('span',{staticClass:"fw1"},[_vm._v("("+_vm._s(_vm.year)+")")])]),(_vm.item.subjects.length)?_c('div',{staticClass:"mv2"},[_c('span',{staticClass:"fw6"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-tag")]),_c('span',{staticClass:"v-mid"},[_vm._v(" "+_vm._s(_vm.item.subjects.length)+" Subject"),(_vm.item.subjects.length > 1)?_c('span',[_vm._v("s")]):_vm._e()])],1),_c('v-chip-group',{staticClass:"font-mono",attrs:{"multiple":"","column":""}},_vm._l((_vm.item.subjects),function(subject){return _c('v-chip',{key:subject,class:_vm.activeFilters['subjects'].includes(subject)
                ? 'teal white--text'
                : '',attrs:{"value":subject,"label":""},on:{"click":function($event){return _vm.$emit('toggle-active-filter', {
                type: 'subjects',
                value: subject,
              })}}},[_c('strong',{staticClass:"mr1"},[_vm._v(_vm._s(subject))]),_c('span',[_vm._v(_vm._s(_vm.filterCountsForSelection['subjects'][subject]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(_vm._s(_vm.activeFilters['subjects'].includes(subject) ? 'mdi-close-circle' : 'mdi-filter-variant')+" ")])],1)}),1)],1):_vm._e(),(_vm.item.locations.length)?_c('div',{staticClass:"mv2"},[_c('span',{staticClass:"fw6"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-map-marker")]),_c('span',{staticClass:"v-mid"},[_vm._v(" "+_vm._s(_vm.item.locations.length)+" Location"),(_vm.item.locations.length > 1)?_c('span',[_vm._v("s")]):_vm._e()])],1),_c('v-chip-group',{staticClass:"font-mono",attrs:{"multiple":"","column":""}},_vm._l((_vm.item.locations),function(location){return _c('v-chip',{key:location,class:_vm.activeFilters['locations'].includes(location)
                ? 'teal white--text'
                : '',attrs:{"value":location,"label":""},on:{"click":function($event){return _vm.$emit('toggle-active-filter', {
                type: 'locations',
                value: location,
              })}}},[_c('strong',{staticClass:"mr1"},[_vm._v(_vm._s(location))]),_c('span',[_vm._v(_vm._s(_vm.filterCountsForSelection['locations'][location]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(_vm._s(_vm.activeFilters['locations'].includes(location) ? 'mdi-close-circle' : 'mdi-filter-variant')+" ")])],1)}),1)],1):_vm._e(),(_vm.item.creators.length)?_c('div',{staticClass:"mv2"},[_c('span',{staticClass:"fw6"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-video")]),_c('span',{staticClass:"v-mid"},[_vm._v(" "+_vm._s(_vm.item.creators.length)+" Creator")]),(_vm.item.creators.length > 1)?_c('span',[_vm._v("s")]):_vm._e()],1),_vm._l((_vm.item.creators),function(creator){return _c('div',{key:creator},[_vm._v(" "+_vm._s(creator)+" ")])})],2):_vm._e(),_c('div',{staticClass:"flex justify-between flex-wrap"},[(!_vm.touchMode)?_c('div',{staticClass:"mr2 mb2"},[_c('a',{attrs:{"href":_vm.item.url,"target":"_blank"}},[_vm._v("See item on Open Images ↗︎")])]):_vm._e(),(_vm.item.layer__asr)?_c('v-btn',{attrs:{"color":"orange darken-2 mr2 mb2"},on:{"click":_vm.onTranscriptClick}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-subtitles")]),_vm._v("Play Transcript ")],1):_vm._e(),_c('v-btn',{attrs:{"color":"orange darken-2"},on:{"click":_vm.onPlaylistClick}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-playlist-play")]),_vm._v("Start Playlist ")],1)],1),_c('div',{staticClass:"absolute ma3 top-0 right-0"},[_c('v-icon',{on:{"click":function($event){return _vm.$emit('toggle-expanded')}}},[_vm._v(" mdi-close ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }